import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Bitdenex | NFT Marketplace",
  keyword:
    "bitcoin, polygon, ETH, ethereum blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
  desc: "The first and largest digital marketplace in the Netherlands for crypto collectibles and non-fungible tokens (NFTs). Create, buy, sell and collect digital artworks.",
};

export default Meta;
