import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestHandler from "./httpClient";

export const fetchNetwork = createAsyncThunk("fetchNetwork", async () => {
    try {
        const res = await requestHandler("network/networkList", "get", "", "");
        return res;
    }
    catch (error) {
        throw error;
    }
});

const categorySlice = createSlice({
    name: "getNetwork",
    initialState: {
        networkLoading: false,
        networkData: [],
        networkError: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNetwork.pending, (state, action) => {
            state.networkLoading = true;
            state.networkError = false;
        })
        builder.addCase(fetchNetwork.fulfilled, (state, action) => {
            state.networkLoading = false;
            state.networkError = false;
            state.networkData = action.payload?.data?.data;
        })
        builder.addCase(fetchNetwork.rejected, (state, action) => {
            state.networkLoading = false;
            state.networkError = true;
        })
    }
});

export default categorySlice.reducer;
