import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestHandler from "./httpClient";

export const collectionDetail = createAsyncThunk("collectionDetail", async (id) => {
  let data = {
    id: id,
  }
  try {
    const res = await requestHandler("collection/collectionDetail", "post", data, "jwt_token");
    return res;
  }
  catch (error) {
    throw error;
  }
});

const getCollectionDetailSlice = createSlice({
  name: "getCollectionDetail",
  initialState: {
    collectionDetailLoading: false,
    collectionDetailData: [],
    collectionDetailError: false
  },
  extraReducers: (builder) => {
    builder.addCase(collectionDetail.pending, (state, action) => {
      state.collectionDetailLoading = true;
      state.collectionDetailError = false;
    })
    builder.addCase(collectionDetail.fulfilled, (state, action) => {
      state.collectionDetailLoading = false;
      state.collectionDetailError = false;
      state.collectionDetailData = action.payload?.data?.data;
    })
    builder.addCase(collectionDetail.rejected, (state, action) => {
      state.collectionDetailLoading = false;
      state.collectionDetailError = true;
    })
  }
});

export default getCollectionDetailSlice.reducer;
