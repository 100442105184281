import axios from "axios";

const BASE_URL = process.env.NEXT_PUBLIC_HOST;

const apiCall = async (
    url,
    type,
    formData,
    auth = '',
    isFile = false
) => {
    if (type === "post") {
        return new Promise((resolve, reject) => {
            let headers = {
                'Content-Type': 'application/json',
            }

            if (isFile) {
                headers = { 'Content-Type': 'multipart/form-data' }
            }
            if (auth === "jwt_token") {
                headers.Authorization = `${localStorage.getItem("jwt_token")}`
            }
            else if (auth.indexOf('JWT') >= 0) {
                headers.Authorization = `${auth}`
            }
            var config = {
                method: type,
                url: `${BASE_URL}/${url}`,
                headers: headers,
            };
            if (formData)
                config.data = formData;
            axios(config)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error.response);
                });
        })
    }
    else {
        if (type === "get") {
            let accessToken = localStorage.getItem("token");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            return new Promise((resolve, reject) => {
                var config = {
                    method: type,
                    url: `${BASE_URL}/${url}`,
                    headers: headers,
                }
                if (formData)
                    config.data = formData;
                axios(config)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            })
        }
    }

}

const requestHandler = async (
    url,
    type,
    formData,
    auth,
    isFile) => {

    var result = await apiCall(url, type, formData, auth, isFile)
    return result
}

export default requestHandler;