import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestHandler from "./httpClient";

export const fetchCollections = createAsyncThunk("fetchCollections", async () => {
    try {
        const res = await requestHandler("collection/collectionList", "get", "", "");
        return res;
    }
    catch (error) {
        throw error;
    }
});

const collectionsSlice = createSlice({
    name: "getCollections",
    initialState: {
        loading: false,
        data: [],
        error: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCollections.pending, (state, action) => {
            state.loading = true;
            state.error = false;
        })
        builder.addCase(fetchCollections.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.data = action.payload?.data?.data;
        })
        builder.addCase(fetchCollections.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        })
    }
});

export default collectionsSlice.reducer;
